<section id="features" class="section features-area ptb_100">
    <div class="shapes-container">
        <div class="shape bg-shape"></div>
    </div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md-6 col-lg-6">
                <div class="features-text">
                    <!-- Headings -->
                    <div class="headings d-flex align-items-center mb-4">
                        <span class="text-uppercase d-none d-sm-block">Daten</span>
                        <h2 class="text-capitalize">Datenschutz</h2>
                    </div>
                    <p class="my-3">Neben der technischen Innovation war es das erklärte Ziel, einen möglichst umfassenden Datenschutz und d.h. minimale Datenerfassung zu gewährleisten. Die App wird keinerlei Analyseprogramme verwenden. Die erfassten anonymen GPS- und Bluethooth-Daten werden ausschließlich für diese App verwendet. Es erfolgt keine Weitergabe der Daten an Dritte. 
                        <br><br>
                        Jegliche Dateneingabe ist rein freiwillig. Die Daten können nicht individuell zugeordnet werden. 
                        Mit der Deinstallation/Löschung der App werden alle bis dahin erfassten Daten unwiederbringlich gelöscht. Eine Überprüfung des Datenschutzes und im Hinblick auf die erfassten Daten kann jederzeit durch offizielle Stellen erfolgen. 
                        <br><br>
                        Mit der App kann somit jeder Nutzer einen kleinen Beitrag dazu leisten, dass die Verbreitung des Virus eingedämmt wird. Je früher die Verbreitung des COVID-19 Erregers eingedämmt ist und je schneller die Neuinfektionszahlen sinken, desto früher können die Beeinträchtigungen unseres täglichen Lebens aufgehoben werden. 
                        <br><br>
                        Die App ist ein Beitrag zur Rückkehr zu unserem alltäglichen Leben. 
                        <br><br>
                        Daher: Schützen Sie anonym Mitmenschen!</p>
                    <!-- Counter List -->
                    <!-- <div class="counter-list">
                        <ul>
                            <li>
                                <div class="single-counter px-4 py-3 text-center">
                                
                                    <div class="counter-icon">
                                        <i class="icofont-repair"></i>
                                    </div>
                                
                                    <div class="counter-text">
                                        <span class="counter d-inline-block mt-3 mb-2">2350</span>
                                        <h5>Users</h5>
                                    </div>
                                </div>
                            </li>
                            <li>
                                
                                <div class="single-counter px-4 py-3 text-center">
                                
                                    <div class="counter-icon">
                                        <i class="icofont-heart-alt"></i>
                                    </div>
                                
                                    <div class="counter-text">
                                        <span class="counter d-inline-block mt-3 mb-2">1895</span>
                                        <h5>Acounts</h5>
                                    </div>
                                </div>
                            </li>
                            <li>
                                
                                <div class="single-counter px-4 py-3 text-center">
                                
                                    <div class="counter-icon">
                                        <i class="icofont-beard"></i>
                                    </div>
                                
                                    <div class="counter-text">
                                        <span class="counter d-inline-block mt-3 mb-2">1580</span>
                                        <h5>Developers</h5>
                                    </div>
                                </div>
                            </li>
                            <li>
                                
                                <div class="single-counter px-4 py-3 text-center">
                                
                                    <div class="counter-icon">
                                        <i class="icofont-safety"></i>
                                    </div>
                                
                                    <div class="counter-text">
                                        <span class="counter d-inline-block mt-3 mb-2">1358</span>
                                        <h5>Download</h5>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div> -->
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-5 offset-lg-1">
                <!-- Featured Items -->
                <div class="featured-items">
                    <ul>
                        <li>
                            <!-- Single Features Item -->
                            <div class="single-features media p-3">
                                <!-- Features Title -->
                                <div class="features-title mr-3">
                                    <img src="assets/img/no_personal_data.svg" alt="">
                                </div>
                                <!-- Features Text -->
                                <div class="features-text media-body">
                                    <h3>Keine persönlichen Daten</h3>
                                    <p>Es wird bei der Registrierung nur eine anonyme Benutzer-ID vergeben.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <!-- Single Features Item -->
                            <div class="single-features media p-3">
                                <!-- Features Title -->
                                <div class="features-title mr-3">
                                    <img src="assets/img/analyse.svg" alt="">
                                </div>
                                <!-- Features Text -->
                                <div class="features-text media-body">
                                    <h3>Analyse</h3>
                                    <p>Es laufen bei der APP keine Analyseprogramme zu Datenerfassung.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <!-- Single Features Item -->
                            <div class="single-features media p-3">
                                <!-- Features Title -->
                                <div class="features-title mr-3">
                                    <img src="assets/img/technology_data.svg" alt="">
                                </div>
                                <!-- Features Text -->
                                <div class="features-text media-body">
                                    <h3>Technologie</h3>
                                    <p>CHECK'N'TRACK verwendet modernste Server und Software Technologie.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <!-- Single Features Item -->
                            <div class="single-features media p-3">
                                <!-- Features Title -->
                                <div class="features-title mr-3">
                                    <img src="assets/img/data_delete.svg" alt="">
                                </div>
                                <!-- Features Text -->
                                <div class="features-text media-body">
                                    <h3>Daten Löschung</h3>
                                    <p>Sie können jederzeit per Knopfdruck alle efassten Daten löschen. </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>