<app-header-one></app-header-one>
<section class="section breadcrumb-area d-flex align-items-center">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<!-- Breamcrumb Content -->
				<div class="breadcrumb-content d-flex flex-column align-items-center text-center">
					<h3>Cookies</h3>
					<ol class="breadcrumb">
						<li class="breadcrumb-item"><a class="text-uppercase" href="index.html">Home</a></li>
						<li class="breadcrumb-item active">Cookies</li>
					</ol>
				</div>
			</div>
		</div>
	</div>
</section>
<section class="section faq-area bg-gray ptb_100">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-12 col-lg-12">
				<!-- Single Faq -->
				<div class="single-faq bg-white px-4 py-3">
					<div class="media">
						<div class="media-body align-self-center">
							<h5>1. Sitzungs-Cookies/Session-Cookies</h5>
						</div>
					</div>
					<br>
					<p class="mb-4">Wir verwenden mit unserem Internetauftritt sog. Cookies. Cookies sind kleine Textdateien oder andere
						Speichertechnologien, die durch den von Ihnen eingesetzten Internet-Browser auf Ihrem Endgerät
						ablegt und gespeichert werden. Durch diese Cookies werden im individuellen Umfang bestimmte
						Informationen von Ihnen, wie beispielsweise Ihre Browser- oder Standortdaten oder Ihre IP-Adresse,
						verarbeitet.  
						<br><br>
						Durch diese Verarbeitung wird unser Internetauftritt benutzerfreundlicher, effektiver und sicherer, da
						die Verarbeitung bspw. die Wiedergabe unseres Internetauftritts in unterschiedlichen Sprachen oder
						das Angebot einer Warenkorbfunktion ermöglicht.
						<br><br>
						Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit b.) DSGVO, sofern diese Cookies Daten zur
						Vertragsanbahnung oder Vertragsabwicklung verarbeitet werden.
						<br><br>
						Falls die Verarbeitung nicht der Vertragsanbahnung oder Vertragsabwicklung dient, liegt unser
						berechtigtes Interesse in der Verbesserung der Funktionalität unseres Internetauftritts.
						Rechtsgrundlage ist in dann Art. 6 Abs. 1 lit. f) DSGVO.
						<br><br>
					Mit Schließen Ihres Internet-Browsers werden diese Session-Cookies gelöscht.</p>
					<div class="media">
						<div class="media-body align-self-center">
							<h5>2. Drittanbieter-Cookies</h5>
						</div>
					</div>
					<br>
					<p class="mb-4">Gegebenenfalls werden mit unserem Internetauftritt auch Cookies von Partnerunternehmen, mit
						denen wir zum Zwecke der Werbung, der Analyse oder der Funktionalitäten unseres Internetauftritts
						zusammenarbeiten, verwendet.
						<br><br>
						Die Einzelheiten hierzu, insbesondere zu den Zwecken und den Rechtsgrundlagen der Verarbeitung
					solcher Drittanbieter-Cookies, entnehmen Sie bitte den nachfolgenden Informationen.</p>
					<div class="media">
						<div class="media-body align-self-center">
							<h5>3. Beseitigungsmöglichkeit</h5>
						</div>
					</div>
					<br>
					<p class="mb-4">Sie können die Installation der Cookies durch eine Einstellung Ihres Internet-Browsers verhindern oder
						einschränken. Ebenfalls können Sie bereits gespeicherte Cookies jederzeit löschen. Die hierfür
						erforderlichen Schritte und Maßnahmen hängen jedoch von Ihrem konkret genutzten Internet-Browser
						ab. Bei Fragen benutzen Sie daher bitte die Hilfefunktion oder Dokumentation Ihres Internet-Browsers
						oder wenden sich an dessen Hersteller bzw. Support. Bei sog. Flash-Cookies kann die Verarbeitung
						allerdings nicht über die Einstellungen des Browsers unterbunden werden. Stattdessen müssen Sie
						insoweit die Einstellung Ihres Flash-Players ändern. Auch die hierfür erforderlichen Schritte und
						Maßnahmen hängen von Ihrem konkret genutzten Flash-Player ab. Bei Fragen benutzen Sie daher
						bitte ebenso die Hilfefunktion oder Dokumentation Ihres Flash-Players oder wenden sich an den
						Hersteller bzw. Benutzer-Support.
						<br><br>
						Sollten Sie die Installation der Cookies verhindern oder einschränken, kann dies allerdings dazu
					führen, dass nicht sämtliche Funktionen unseres Internetauftritts vollumfänglich nutzbar sind.</p>
				</div>
			</div>            
		</div>
	</div>
</section>
<app-footer></app-footer>