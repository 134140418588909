<section id="screenshots" class="section screenshots-area ptb_100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <!-- App Screenshot Slider Area -->
                <div class="app-screenshots">
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/slider1.png" alt="" class="text-center">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/slider1.png" data-fancybox="images" class="text-center"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/slider2.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/slider2.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/slider3.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/slider3.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/slider4.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/slider4.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/slider5.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/slider5.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/slider6.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/slider6.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/slider7.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/slider7.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/slider8.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/slider8.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/slider9.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/slider9.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/slider10.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/slider10.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/slider11.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/slider11.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/slider12.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/slider12.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/slider13.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/slider13.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/slider14.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/slider14.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/slider15.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/slider15.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/slider16.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/slider16.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/slider17.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/slider17.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/slider18.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/slider18.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="assets/img/slider19.png" alt="">
                        <!-- Screenshots Overlay -->
                        <div class="screenshots-overlay">
                            <a href="assets/img/slider19.png" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>