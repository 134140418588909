<div class="miami">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-one></app-header-one>
        <app-hero-one></app-hero-one>
        <app-promo-one></app-promo-one>
        <app-about></app-about>
        <app-work></app-work>
        <app-features></app-features>
        <app-screenshot></app-screenshot>
        <app-about-us></app-about-us>
        <!-- <app-pricing></app-pricing> -->
        <!-- <app-reviews></app-reviews> -->
        <!-- <app-team></app-team> -->
        <app-newsletter></app-newsletter>
        <!-- <app-download></app-download> -->
        <!-- <app-blog></app-blog> -->
        <!-- <app-contact></app-contact> -->
        <app-footer></app-footer>
    </div>
</div>