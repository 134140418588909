<footer class="section footer-area footer-bg">
    <!-- Footer Top -->
    <!-- Footer Bottom -->
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Copyright Area -->
                    <div class="copyright-area text-center py-4 text-center">
                        <!-- Copyright Left -->
                        <div class="copyright-left text-white-50">&copy; Copyright 2020, powered by Fioretto Media.</div>
                    </div>
                    <div class="copyright-text text-white text-center mb-4">
                        <a class="text-white" [routerLink]="['/impressum']">Impressum</a> &nbsp; | 
                        <a class="text-white" [routerLink]="['/datenschutzbestimmungen']">&nbsp; Datenschutzbestimmungen</a> &nbsp; | 
                        <a class="text-white" [routerLink]="['/cookies']">&nbsp; Cookies</a> &nbsp; | 
                        <a class="text-white" [routerLink]="['/nutzungsbedingungen']">&nbsp; Nutzungsbedingungen</a></div>
                </div>
            </div>
        </div>
    </div>
</footer>