import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormArray, FormControl, CheckboxControlValueAccessor} from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AccountService } from 'src/app/_services/account.service';
import { GlobalConstants } from 'src/app/common/global-constants';
@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
	personalInfoFormGroup: FormGroup;
	addressFormGroup: FormGroup;
	//moreinfoFormGroup: FormGroup;
	data:any;
	submitted = false;
	success = false;
	error = false;
	loginLink = GlobalConstants.loginLink;
	active = 'active';
	clickActiveMenu1 = '';
	clickActiveMenu2 = '';
	activeMenuTabStyle = '';
	activeMenu1TabStyle = '';
	activeMenu2TabStyle = '';
	countryName:any;
	localityName='';
	password = '';
	hide = true;
	minDate = new Date(1900, 0, 1);
	maxDate = new Date(2020, 0, 1);
	companyTitle = false;
	defaultIdNumberValidator = [Validators.required];
	message = '';

	constructor(private _formBuilder: FormBuilder, private router: Router, private accountService: AccountService) { }

	ngOnInit(): void {
		this.active = this.active;
		this.activeMenuTabStyle = 'activeMenuTabStyle';
		//this.autoPassword();
		this.personalInfoFormGroup = this._formBuilder.group({
			title: ['', Validators.required],
			companyName: ['&nbsp;', Validators.required],
			firstName: ['', Validators.required],
			lastName: ['', Validators.required],
			email: ['', [Validators.required,Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
			dob: ['', Validators.required],
			nationality: ['', Validators.required],
			birthPlace: ['', Validators.required],
			phone_number: ['', Validators.required]
		});
		// const fields = {};
		// const personalInfoValue = this.personalInfoFormGroup.controls;
		// this.personalInfoFormGroup.valueChanges.subscribe(() => {
		// 	for (const field in personalInfoValue) {
		// 		if (!personalInfoValue.hasOwnProperty(field)) {
		//          	continue;
		//      	}
		//      	fields[field] = {};
		//      	const control = this.personalInfoFormGroup.get(field);
		//      	console.log(control);
		//      	if (control && control.dirty && !control.valid) {
		//      		fields[field] = control.errors;
		//      	}
		// 	}
		// });	
		this.addressFormGroup = this._formBuilder.group({
			street: ['', Validators.required],
			streetNumber: ['', Validators.required],
			postCode: ['', Validators.required],
			city: ['', Validators.required],
			additionalReference: [''],
			countryOfResidence: ['', Validators.required],
			isAgree: ['',Validators.required]
		});

		/*this.moreinfoFormGroup = this._formBuilder.group({
			password: ['', Validators.required],
			phone_number: ['', Validators.required]
		});*/
	}
	get personalInfoForm() {
		return this.personalInfoFormGroup.controls;
	}

	get addressInfoForm() {
		return this.addressFormGroup.controls;
	}

	/*get moreinfoForm(){
		return this.moreinfoFormGroup.controls;
	}*/
	//Get AutoGenerated Password
	/*autoPassword(){
	    console.log('in autopassword function');
	    this.accountService.getPassword()
	    .pipe(first())
	    .subscribe(data => {
	      this.password = data.toString();
	      this.moreinfoFormGroup.patchValue({password: this.password});
	      console.log('password ====', data);
	      return data;
	      },
	      error  => {
	        console.error(error.message);
	        console.log("Error", error['error']);      
	      });
	}*/
	register() {
		function capitalizeFirstLetter(string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		}
		this.data = {
			// Personal Information
			title: this.personalInfoForm.title.value,
			companyName: this.personalInfoForm.companyName.value,
			firstname: this.personalInfoForm.firstName.value,
			lastname: this.personalInfoForm.lastName.value,
			email: this.personalInfoForm.email.value,
			contactno: this.personalInfoForm.phone_number.value,
			dateofbirth: this.personalInfoForm.dob.value,
			// nationality: this.personalInfoForm.nationality.value,
			nationality:capitalizeFirstLetter(this.personalInfoForm.nationality.value),
			birth_place: this.personalInfoForm.birthPlace.value,
			
			// Address Information
			strassa: this.addressInfoForm.street.value,
			strno: this.addressInfoForm.streetNumber.value,
			plz: this.addressInfoForm.postCode.value,
			city: this.addressInfoForm.city.value,
			additionalReference: this.addressInfoForm.additionalReference.value,
			current_country: this.addressInfoForm.countryOfResidence.value,
			//More Information
			//password: this.moreinfoForm.password.value,
			//contactno: this.moreinfoForm.phone_number.value,
			status: '1',
		}
		//console.log("dataPost",this.data);
		//console.log(data);
		this.accountService.register(this.data)
		.pipe(first())
		.subscribe(
			data => {
				if(data['status'] == '200'){
					this.success = true;
					this.message = data['success'];
					this.loginLink = this.loginLink;
					this.router.navigate(['../register']);
				}else{
					this.error = true;
					this.message = data['error'];
				}
				/*console.log("data",data);
				this.success = true;
				this.loginLink = this.loginLink;
				this.router.navigate(['../register']);*/
			},
			error => {
				
			});
	}
		next(menu){
			this.error = false;
			if(menu == 'home'){
				this.active = 'active show';
				this.clickActiveMenu1 = '';	
				this.clickActiveMenu2 = '';	
				this.activeMenuTabStyle = 'activeMenuTabStyle';
				this.activeMenu1TabStyle = '';
				this.activeMenu2TabStyle = '';
			}
			else if(menu == 'menu1'){
				this.active = '';
				this.clickActiveMenu1 = 'active show';	
				this.clickActiveMenu2 = '';	
				this.activeMenuTabStyle = '';
				this.activeMenu1TabStyle = 'activeMenuTabStyle';
				this.activeMenu2TabStyle = '';
			}
			/*else if(menu == 'menu2'){
				this.active = '';
				this.clickActiveMenu1 = '';	
				this.clickActiveMenu2 = 'active show';	
				this.activeMenuTabStyle = '';
				this.activeMenu1TabStyle = '';
				this.activeMenu2TabStyle = 'activeMenuTabStyle';
			}*/
		}
		handleAddressChange(data){
		    const splitArr = data.address_components;
		    this.getCountry(splitArr);
		}	
		handleAllFields(data){
		    console.log("original data = ", data);
		    const splitArr = data.address_components;
		    this.getAllFields(splitArr);
		}
		getCountry(data):any{
		    let that=this;
		    const splitArr = data;
		    console.log(splitArr);
		    splitArr.forEach(function(i,k){
		      let content: any = i.types;
		      //console.log(content);
		      if(content.length > 1){
		        const countryArr = content;
		        for (let index = 0; index < content.length; index++) {
		          if(countryArr[index] === 'country'){
		            that.countryName = i.long_name;
		          }
		          if(countryArr[index] === 'locality'){
		            that.localityName = i.short_name;
		          }
		          let localityCountry = that.localityName+","+that.countryName;
		          that.personalInfoFormGroup.patchValue({birthPlace: localityCountry});
		        }
		      }
		    });
	    }
	    getAllFields(data):any{
		    let that=this;
		    const splitArr = data;
		    let str = "";
		    console.log("data", splitArr);
		    splitArr.forEach(function(i,k){
		      let content: any = i.types;
		      const found = content.find(element => element = 'street_number');
		      if(found === 'street_number'){
		        that.addressFormGroup.patchValue({streetNumber: i.short_name});
		      }else if(found === 'postal_code'){
		        that.addressFormGroup.patchValue({postCode: i.short_name});
		      }else if(found === 'route'){
		        str = i.short_name;  
		      }
		      if(content.length > 1){
		        const countryArr = content;
		        //console.log(countryArr);
		        for (let index = 0; index < content.length; index++) {
		          if(countryArr[index] === 'country'){
		            console.log("country",i);
		            that.addressFormGroup.patchValue({countryOfResidence: i.long_name});
		          }
		          if(countryArr[index] === 'locality'){
		            that.addressFormGroup.patchValue({city: i.short_name});
		          }
		        }
		      }
		    });
		    if(str.length > 0){
		        that.addressFormGroup.patchValue({street: str});
		    }else{
		        that.addressFormGroup.patchValue({street: ""});
		    }
		}
		titleChange($event){
			this.companyTitle = false;
			if(this.personalInfoFormGroup.get('title').value == 'Firma'){
				this.companyTitle = true;
				this.personalInfoFormGroup.patchValue({companyName: this.personalInfoFormGroup.value.companyName});
			}else{
				this.personalInfoFormGroup.patchValue({companyName: '&nbsp;'});
			}
		}
	}
