import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/common/global-constants';
@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.css']
})
export class HeaderOneComponent implements OnInit {
  loginLink:any;	
  constructor() { }

  ngOnInit(): void {
  	this.loginLink = GlobalConstants.loginLink;
  }

}
