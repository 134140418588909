<section id="about" class="section about-app-area ptb_100">
    <div class="shapes-container">
        <div class="shape-1"></div>
    </div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-6">
                <!-- About Text -->
                <div class="about-text">
                    <!-- Headings -->
                    <div class="headings d-flex align-items-center mb-4">
                        <span class="text-uppercase d-none d-sm-block">About</span>
                        <h2 class="text-capitalize">CHECK'N'TRACK <br>Tracking App</h2>
                    </div>
                    <p class="my-3">Mit einer innovativen App kann jeder Nutzer einen Beitrag zur Eindämmung des Corona-Virus (Covid-19) leisten. Die Fioretto Media GmbH hat in den letzten Wochen mit einem Team von ca. 40 Experten eine Handy-App entwickelt, die den Kontakt von Menschen mittels GPS und Bluetooth erfasst und somit mögliche Infektionsketten darstellen und nachzeichnen kann.</p>
                    <p class="d-none d-sm-block my-3">Das erklärte Ziel bei der Entwicklung der Check´N´Track App war, dass jeder Nutzer einfach und datenschutzkonform seinen Gesundheitszustand überwachen (check) und seinen Kontakt mit Corona infizierten Mitmenschen verfolgen (track) kann und dies absolut anonym.</p>
                    <!-- Store Buttons -->
                    <div class="button-group store-buttons">
                        <a href="#" class="btn btn-bordered">
                            <i class="icofont icofont-brand-android-robot dsp-tc"></i>
                            <p class="dsp-tc">GET IT ON
                                <br> <span>Google Play</span></p>
                        </a>
                        <a href="#" class="btn btn-bordered">
                            <i class="icofont icofont-brand-apple dsp-tc"></i>
                            <p class="dsp-tc">AVAILABLE ON
                                <br> <span>Apple Store</span></p>
                        </a>
                    </div>
                    <br>
                    <div class="comming-soon-cls">* Bald verfügbar</div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <!-- About Thumb -->
                <div class="about-thumb text-center d-none d-lg-block">
                    <img src="assets/img/about_us_final.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</section>