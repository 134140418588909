<section id="labs" class="section about-us ptb_100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-8 col-md-6 col-lg-5 mx-auto mb-5 mb-lg-0">
                <img class="mx-auto w-75" src="assets/img/doctor.png" alt="">
            </div>
            <div class="col-12 col-lg-6">
                <h2>Ärzte und Labore</h2>
                <p class="my-3">Im Falle einer Infektion erhält der Benutzer umgehend einen Hinweis und den Rat, einen Arzt aufzusuchen und sich erstmal in Quarantäne zu begeben.
                    <br><br>
                    Der behandelnde Arzt oder das untersuchende Labor kann sich über ein bereitgestelltes Portal verifizieren und freischalten lassen, so dass er einen positiven Befund des Patienten anhand der Benutzer-ID und damit anonym melden kann. 
                    <br><br>
                    Personen, die in nahem Kontakt zu dem infizierten Mitmenschen standen, werden über das bestehende Risiko sodann informiert und ihnen empfohlen, einen Arzt zu konsultieren. 
                    <br><br>
                    Wichtig hierbei ist, dass niemand die Personalien oder Benutzer-ID des infizierten Mitmenschen erfährt und sonst auch keine personalisierten Daten erfasst oder übermittelt werden. 
                    <br><br>
                So lässt sich feststellen, ob Kontakt zu einem infizierten Covid-19 Patienten bestanden hat. </p>
                <!-- Counter List -->
                <div class="counter-list">
                    <ul>
                        <li>
                            <!-- Single Counter -->
                            <div class="single-counter py-3 text-center">
                                <!-- Counter Icon -->
                                <!-- <div class="counter-icon mb-4">
                                    <i class="icofont-login"></i>
                                </div> -->
                                <!-- Counter Text -->
                                <!-- <div class="counter-text mb-2">
                                    <h5><a href="http://fiorettosystems.com/Frontend/dist/#/" target="_blank">Login</a></h5>
                                </div> -->
                                <div class="counter-text">
                                    <a href="{{loginLink}}" target="_blank" class="btn btn-primary w-100 mt-3 login-btn mat-raised-button mat-button-base">
                                        <span>Login</span>
                                    </a>
                                </div>
                            </div>
                        </li>
                        <li>
                            <!-- Single Counter -->
                            <div class="single-counter px-4 py-3 text-center">
                                <!-- <div class="counter-icon mb-4">
                                    <img class="register-icon-class" src="assets/img/register_icon.png">
                                </div>
                                <div class="counter-text">
                                    <h5><a href="#">Register</a></h5>
                                </div> -->
                                <div class="counter-text">
                                    <a [routerLink]="['/register']" class="btn btn-primary mt-3 login-btn mat-raised-button mat-button-base">
                                        <span>Registrieren</span>
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="fs-logo-div">powered by <img class="fs-logo" src="assets/img/fioretto-systems.png"></div>
                </div>

            </div>
        </div>
    </div>
</section>