<app-header-one></app-header-one>
<section class="section breadcrumb-area d-flex align-items-center">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<!-- Breamcrumb Content -->
				<div class="breadcrumb-content d-flex flex-column align-items-center text-center">
					<h3>Registrieren</h3>
					<ol class="breadcrumb">
						<li class="breadcrumb-item"><a class="text-uppercase" href="index.html">Home</a></li>
						<li class="breadcrumb-item active">Registrieren</li>
					</ol>
				</div>
			</div>
		</div>
	</div>
</section>
<section class="section faq-area bg-gray ptb_100">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-12 col-lg-12">
				<!-- Single Faq -->
				<div class="single-faq bg-white px-4 py-3">
					<div class="col-md-6 offset-md-3 register-success" *ngIf="success && !error"> 
						<div class="alert-success text-center p-3">{{ message }}</div>
						<div class="counter-text text-center">
							<a class="btn btn-primary w-30 mt-3 login-btn mat-raised-button mat-button-base" href="./">Zur Startseite</a>
						</div>
					</div>
					<ul class="nav nav-tabs mt-4 nav-justified" *ngIf="!success">
						<li class="active"><button data-toggle="tab" href="#home" class="tab-btn ripple p-2 {{ activeMenuTabStyle }}" (click)="next('home')"><span class="step-number">1</span>Persönliche Daten</button></li>
						<li><button data-toggle="tab" href="#menu1" class="tab-btn ripple p-2 {{ activeMenu1TabStyle }}" (click)="next('menu1')" [disabled]='!fsp.form.valid'><span class="step-number">2</span>Offizieller Wohnsitz</button></li>
						<!-- <li><button data-toggle="tab" href="#menu2" class="tab-btn ripple p-2 {{ activeMenu2TabStyle }}" (click)="next('menu2')" ><span class="step-number">3</span>Mehr Info</button></li> -->
					</ul>
					<div class="tab-content">
						<div id="home" class="tab-pane in p-5 {{ clickActiveMenu1 ? '' : active }}">
							<form [formGroup]="personalInfoFormGroup" #fsp="ngForm" novalidate="" class="login-form">
								<div class="form-group mb-3">
									<select formControlName="title" class="form-control" (change)="titleChange($event)">    
										<option value="">Anrede</option>
										<option value="Herr">Herr</option>
										<option value="Frau">Frau</option>
										<option value="Firma">Firma</option>
									</select>
								</div>
								<div class="mb-4">
									<span *ngIf="personalInfoFormGroup.get('title').invalid && (personalInfoFormGroup.get('title').dirty || personalInfoFormGroup.get('title').touched)" class="error">Bitte title auswählen</span>
								</div>
								<div class="input-group mb-3" *ngIf="companyTitle">
									<input type="text" class="form-control" placeholder="Firmenname" aria-label="Firmenname" aria-describedby="basic-addon2" formControlName="companyName">
								</div>
								<div class="mb-4" *ngIf="companyTitle">
									<span *ngIf="personalInfoFormGroup.get('companyName').invalid && (personalInfoFormGroup.get('companyName').dirty || personalInfoFormGroup.get('companyName').touched)" class="error">Bitte Firmenname ein</span>
								</div>
								<div class="input-group mb-3">
									<input type="text" class="form-control mr-3" placeholder="Vorname" aria-label="Vorname" aria-describedby="basic-addon2" formControlName="firstName">
									<input type="text" class="form-control" placeholder="Nachname" aria-label="Nachname" aria-describedby="basic-addon2" formControlName="lastName">
									<!-- <span *ngIf="!personalInfoFormGroup.get('lastName').valid">Please select last name</span> -->
								</div>
								<div class="mb-4">
									<span *ngIf="personalInfoFormGroup.get('firstName').invalid && (personalInfoFormGroup.get('firstName').dirty || personalInfoFormGroup.get('firstName').touched)" class="error bothFieldsErr">Bitte Vornamen eingeben</span>
									<span *ngIf="personalInfoFormGroup.get('lastName').invalid && (personalInfoFormGroup.get('lastName').dirty || personalInfoFormGroup.get('lastName').touched)" class="error">Bitte Nachname eingeben</span>
								</div>
								<div class="input-group mb-3">
									<input type="email" class="form-control" placeholder="E-Mail" aria-label="E-Mail" aria-describedby="basic-addon2" formControlName="email" email="true">
								</div>
								<div class="mb-4">
									<span *ngIf="personalInfoFormGroup.get('email').invalid && (personalInfoFormGroup.get('email').dirty || personalInfoFormGroup.get('email').touched)" class="error">Bitte tragen Sie eine gültige E-Mail ein</span>
								</div>
								<div class="input-group mb-3">
									<international-phone-number formControlName="phone_number" placeholder="Telefonnummer eingeben" [maxlength]="20" [defaultCountry]="'de'"
									[required]="true" name="phone_number" class="test"></international-phone-number>
								</div>
								<div class="mb-4">
									<span *ngIf="personalInfoFormGroup.get('phone_number').invalid && (personalInfoFormGroup.get('phone_number').dirty || personalInfoFormGroup.get('phone_number').touched)" class="error">Bitte Telefonnummer eingeben</span>
								</div>
								<div class="input-group mb-3">
									<input type="text" class="form-control" placeholder="Geburtsdatum" aria-label="Geburtsdatum" aria-describedby="basic-addon2" formControlName="dob" bsDatepicker [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }" [minDate]="minDate" [maxDate]="maxDate" id="dob">
									<div class="input-group-prepend">
										<label class="input-group-text ripple" for="dob">
											<span class="fa fa-calendar"></span>
										</label> 
									</div>
								</div>
								<div class="mb-4">
									<span *ngIf="personalInfoFormGroup.get('dob').invalid && (personalInfoFormGroup.get('dob').dirty || personalInfoFormGroup.get('dob').touched)" class="error">Bitte Geburtsdatum auswählen</span>
								</div>
								<div class="input-group mb-3">
									<input type="text" class="form-control" placeholder="Geburtsort" aria-label="Geburtsort" aria-describedby="basic-addon2" formControlName="birthPlace" ngx-google-places-autocomplete #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)">
								</div>
								<div class="mb-4">
									<span *ngIf="personalInfoFormGroup.get('birthPlace').invalid && (personalInfoFormGroup.get('birthPlace').dirty || personalInfoFormGroup.get('birthPlace').touched)" class="error">Bitte Geburtsort auswählen</span>
								</div>
								<div class="input-group mb-3">
									<select class="form-control" aria-label="Nationalität" aria-describedby="basic-addon2" formControlName="nationality">
										<option value="">Nationalität</option>
										<option value="afghanisch">Afghanisch</option>
										<option value="ägyptisch">Ägyptisch</option>
										<option value="albanisch">Albanisch</option>
										<option value="algerisch">Algerisch</option>
										<option value="andorranisch">Andorranisch</option>
										<option value="angolanisch">Angolanisch</option>
										<option value="antiguanisch">Antiguanisch</option>
										<option value="äquatorialguineisch">Äquatorialguineisch</option>
										<option value="argentinisch">Argentinisch</option>
										<option value="armenisch">Armenisch</option>
										<option value="aserbaidschanisch">Aserbaidschanisch</option>
										<option value="äthiopisch">Äthiopisch</option>
										<option value="australisch">Australisch</option>
										<option value="bahamaisch">Bahamaisch</option>
										<option value="bahrainisch">Bahrainisch</option>
										<option value="bangladeschisch">Bangladeschisch</option>
										<option value="barbadisch">Barbadisch</option>
										<option value="belgisch">Belgisch</option>
										<option value="belizisch">Belizisch</option>
										<option value="beninisch">Beninisch</option>
										<option value="bhutanisch">Bhutanisch</option>
										<option value="bolivianisch">Bolivianisch</option>
										<option value="bosnisch herzegowinisch">Bosnisch herzegowinisch</option>
										<option value="botsuanisch">Botsuanisch</option>
										<option value="brasilianisch">Brasilianisch</option>
										<option value="bruneiisch">Bruneiisch</option>
										<option value="bulgarisch">Bulgarisch</option>
										<option value="burkinisch">Burkinisch</option>
										<option value="burundisch">Burundisch</option>
										<option value="chilenisch">Chilenisch</option>
										<option value="chinesisch">Chinesisch</option>
										<option value="costa-ricanisch">Costa-ricanisch</option>
										<option value="dänisch">Dänisch</option>
										<option value="kongolesisch">Kongolesisch</option>
										<option value="deutsch">Deutsch</option>
										<option value="dominicanisch">Dominicanisch</option>
										<option value="dominikanisch">Dominikanisch</option>
										<option value="dschibutisch">Dschibutisch</option>
										<option value="ecuadorianisch">Ecuadorianisch</option>
										<option value="salvadorianisch">Salvadorianisch</option>
										<option value="ivorisch">Ivorisch</option>
										<option value="englisch">Englisch</option>
										<option value="eritreisch">Eritreisch</option>
										<option value="estnisch">Estnisch</option>
										<option value="fidschianisch">Fidschianisch</option>
										<option value="finnisch">Finnisch</option>
										<option value="französisch">Französisch</option>
										<option value="gabunisch">Gabunisch</option>
										<option value="gambisch">Gambisch</option>
										<option value="georgisch">Georgisch</option>
										<option value="ghanaisch">Ghanaisch</option>
										<option value="grenadisch">Grenadisch</option>
										<option value="griechisch">Griechisch</option>
										<option value="grönländisch">Grönländisch</option>
										<option value="britisch">Britisch</option>
										<option value="guatemaltekisch">Guatemaltekisch</option>
										<option value="guineisch">Guineisch</option>
										<option value="guinea-bissauisch">Guinea-bissauisch</option>
										<option value="guyanisch">Guyanisch</option>
										<option value="haitianisch">Haitianisch</option>
										<option value="honduranisch">Honduranisch</option>
										<option value="indisch">Indisch</option>
										<option value="indonesisch">Indonesisch</option>
										<option value="irakisch">Irakisch</option>
										<option value="iranisch">Iranisch</option>
										<option value="irisch">Irisch</option>
										<option value="isländisch">Isländisch</option>
										<option value="israelisch">Israelisch</option>
										<option value="italienisch">Italienisch</option>
										<option value="jamaikanisch">Jamaikanisch</option>
										<option value="japanisch">Japanisch</option>
										<option value="jemenitisch">Jemenitisch</option>
										<option value="jordanisch">Jordanisch</option>
										<option value="kambodschanisch">Kambodschanisch</option>
										<option value="kamerunisch">Kamerunisch</option>
										<option value="kanadisch">Kanadisch</option>
										<option value="kapverdisch">Kapverdisch</option>
										<option value="kasachisch">Kasachisch</option>
										<option value="katarisch">Katarisch</option>
										<option value="kenianisch">Kenianisch</option>
										<option value="kirgisisch">Kirgisisch</option>
										<option value="kiribatisch">Kiribatisch</option>
										<option value="kolumbianisch">Kolumbianisch</option>
										<option value="komorisch">Komorisch</option>
										<option value="kongolesisch">Kongolesisch</option>
										<option value="kosovarisch">Kosovarisch</option>
										<option value="kroatisch">Kroatisch</option>
										<option value="kubanisch">Kubanisch</option>
										<option value="kuwaitisch">Kuwaitisch</option>
										<option value="laotisch">Laotisch</option>
										<option value="lesothisch">Lesothisch</option>
										<option value="lettisch">Lettisch</option>
										<option value="libanesisch">Libanesisch</option>
										<option value="liberianisch">Liberianisch</option>
										<option value="libysch">Libysch</option>
										<option value="liechtensteinisch">Liechtensteinisch</option>
										<option value="litauisch">Litauisch</option>
										<option value="luxemburgisch">Luxemburgisch</option>
										<option value="madagassisch">Madagassisch</option>
										<option value="malawisch">Malawisch</option>
										<option value="malaysisch">Malaysisch</option>
										<option value="maledivisch">Maledivisch</option>
										<option value="malisch">Malisch</option>
										<option value="maltesisch">Maltesisch</option>
										<option value="marokkanisch">Marokkanisch</option>
										<option value="marshallisch">Marshallisch</option>
										<option value="mauretanisch">Mauretanisch</option>
										<option value="mauritisch">Mauritisch</option>
										<option value="mazedonisch">Mazedonisch</option>
										<option value="mexikanisch">Mexikanisch</option>
										<option value="mikronesisch">Mikronesisch</option>
										<option value="moldauisch">Moldauisch</option>
										<option value="monegassisch">Monegassisch</option>
										<option value="mongolisch">Mongolisch</option>
										<option value="montenegrinisch">Montenegrinisch</option>
										<option value="mosambikanisch">Mosambikanisch</option>
										<option value="myanmarisch">Myanmarisch</option>
										<option value="namibisch">Namibisch</option>
										<option value="nauruisch">Nauruisch</option>
										<option value="nepalesisch">Nepalesisch</option>
										<option value="neuseeländisch">Neuseeländisch</option>
										<option value="nicaraguanisch">Nicaraguanisch</option>
										<option value="niederländisch">Niederländisch</option>
										<option value="nigrisch">Nigrisch</option>
										<option value="nigerianisch">Nigerianisch</option>
										<option value="nordkoreanisch">Nordkoreanisch</option>
										<option value="norwegisch">Norwegisch</option>
										<option value="omanisch">Ömanisch</option>
										<option value="österreichisch">Österreichisch</option>
										<option value="pakistanisch">Pakistanisch</option>
										<option value="palästinensisch">Palästinensisch</option>
										<option value="palauisch">Palauisch</option>
										<option value="panamaisch">Panamaisch</option>
										<option value="papua-neuguineisch">Papua-neuguineisch</option>
										<option value="paraguayisch">Paraguayisch</option>
										<option value="peruanisch">Peruanisch</option>
										<option value="philippinisch">Philippinisch</option>
										<option value="polnisch">Polnisch</option>
										<option value="portugiesisch">Portugiesisch</option>
										<option value="puerto-ricanisch">Puerto-ricanisch</option>
										<option value="kongolesisch">Kongolesisch</option>
										<option value="ruandisch">Ruandisch</option>
										<option value="rumänisch">Rumänisch</option>
										<option value="russisch">Russisch</option>
										<option value="salomonisch">Salomonisch</option>
										<option value="sambisch">Sambisch</option>
										<option value="samoanisch">Samoanisch</option>
										<option value="san-marinesisch">San-marinesisch</option>
										<option value="saudi-arabisch">Saudi-arabisch</option>
										<option value="schottisch">Schottisch</option>
										<option value="schwedisch">Schwedisch</option>
										<option value="schweizerisch">Schweizerisch</option>
										<option value="senegalesisch">Senegalesisch</option>
										<option value="serbisch">Serbisch</option>
										<option value="seychellisch">Seychellisch</option>
										<option value="sierra-leonisch">Sierra-leonisch</option>
										<option value="simbabwisch">Simbabwisch</option>
										<option value="singapurisch">Singapurisch</option>
										<option value="slowakisch">Slowenisch</option>
										<option value="somalisch">Somalisch</option>
										<option value="spanisch">Spanisch</option>
										<option value="sri-lankisch">Sri-lankisch</option>
										<option value="lucianisch">Lucianisch</option>
										<option value="vincentisch">Vincentisch</option>
										<option value="südafrikanisch">Südafrikanisch</option>
										<option value="sudanesisch">Sudanesisch</option>
										<option value="südkoreanisch">Südkoreanisch</option>
										<option value="surinamisch">Surinamisch</option>
										<option value="swasiländisch">Swasiländisch</option>
										<option value="syrisch">Syrisch</option>
										<option value="são-toméisch">São-toméisch</option>
										<option value="tadschikisch">Tadschikisch</option>
										<option value="taiwanisch">Taiwanisch</option>
										<option value="tansanisch">Tansanisch</option>
										<option value="thailändisch">Thailändisch</option>
										<option value="tibetisch">Tibetisch</option>
										<option value="timoresisch">Timoresisch</option>
										<option value="togoisch">Togoisch</option>
										<option value="tongaisch">Tongaisch</option>
										<option value="tschadisch">Tschadisch</option>
										<option value="tschechisch">Tschechisch</option>
										<option value="tunesisch">Tunesisch</option>
										<option value="türkisch">Türkisch</option>
										<option value="turkmenisch">Turkmenisch</option>
										<option value="tuvaluisch">Tuvaluisch</option>
										<option value="ugandisch">Ugandisch</option>
										<option value="ukrainisch">Ukrainisch</option>
										<option value="ungarisch">Ungarisch</option>
										<option value="uruguayisch">Uruguayisch</option>
										<option value="amerikanisch">Amerikanisch</option>
										<option value="usbekisch">Üsbekisch</option>
										<option value="vanuatuisch">Vanuatuisch</option>
										<option value="vatikanisch">Vatikanisch</option>
										<option value="venezolanisch">Venezolanisch</option>
										<option value="vietnamesisch">Vietnamesisch</option>
										<option value="walisisch">Walisisch</option>
										<option value="weißrussisch">Weißrussisch</option>
										<option value="zentralafrikanisch">Zentralafrikanisch</option>
										<option value="zyprisch">Zyprisch</option>
									</select>
								</div>
								<div class="mb-4">
									<span *ngIf="personalInfoFormGroup.get('nationality').invalid && (personalInfoFormGroup.get('nationality').dirty || personalInfoFormGroup.get('nationality').touched)" class="error">Bitte Nationalität auswählen</span>
								</div>
								<div class="counter-text text-right">
									<button class="btn btn-primary w-30 mt-3 login-btn mat-raised-button mat-button-base" (click)="next('menu1')" [disabled]='!fsp.form.valid'>Weiter</button>
								</div>
							</form>
						</div>
						<div class="col-md-6 offset-md-3" *ngIf="error"> 
							<div class="alert-danger text-center">{{ message }}</div>
						</div>
						<div id="menu1" class="tab-pane fade p-5 {{ clickActiveMenu1 }}" *ngIf="!success">
							<form [formGroup]="addressFormGroup"  #fso="ngForm" novalidate="" class="login-form">
								<div class="input-group mb-3">
									<input type="text" class="form-control" placeholder="Straße" aria-label="Straße" aria-describedby="basic-addon2" formControlName="street" ngx-google-places-autocomplete #placesRef="ngx-places" (onAddressChange)="handleAllFields($event)">
								</div>
								<div class="mb-4">
									<span *ngIf="addressFormGroup.get('street').invalid && (addressFormGroup.get('street').dirty || addressFormGroup.get('street').touched)" class="error">Bitte Straße auswählen</span>
								</div>
								<div class="input-group mb-3">
									<input type="text" class="form-control mr-3" placeholder="Nr." aria-label="Nr." aria-describedby="basic-addon2" formControlName="streetNumber">
									<input type="text" class="form-control" placeholder="PLZ" aria-label="PLZ" aria-describedby="basic-addon2" formControlName="postCode">
								</div>
								<div class="mb-4">
									<span *ngIf="addressFormGroup.get('streetNumber').invalid && (addressFormGroup.get('streetNumber').dirty || addressFormGroup.get('streetNumber').touched)" class="error bothFieldsErr">Bitte Nr. eingeben</span>
									<span *ngIf="addressFormGroup.get('postCode').invalid && (addressFormGroup.get('postCode').dirty || addressFormGroup.get('postCode').touched)" class="error">Bitte PLZ eingeben</span>
								</div>
								<div class="input-group mb-3">
									<input type="text" class="form-control" placeholder="Stadt" aria-label="Stadt" aria-describedby="basic-addon2" formControlName="city">
								</div>
								<div class="mb-4">
									<span *ngIf="addressFormGroup.get('city').invalid && (addressFormGroup.get('city').dirty || addressFormGroup.get('city').touched)" class="error">Bitte Stadt eingeben</span>
								</div>
								<div class="input-group mb-3">
									<input type="text" class="form-control" placeholder="C/O Appartmentnummer, Türcode, usw." aria-label="C/O Appartmentnummer, Türcode, usw." aria-describedby="basic-addon2" formControlName="additionalReference">
								</div>
								<span *ngIf="addressFormGroup.get('additionalReference').invalid && (addressFormGroup.get('additionalReference').dirty || addressFormGroup.get('additionalReference').touched)" class="error">Bitte C/O Appartmentnummer, Türcode, usw. eingeben</span>
								<div class="input-group mb-3">
									<input type="text" class="form-control" placeholder="Land des Wohnsitzes" aria-label="Land des Wohnsitzes" aria-describedby="basic-addon2" formControlName="countryOfResidence">
								</div>
								<span *ngIf="addressFormGroup.get('countryOfResidence').invalid && (addressFormGroup.get('countryOfResidence').dirty || addressFormGroup.get('countryOfResidence').touched)" class="error">Bitte Land des Wohnsitzes eingeben</span>
								<div class="custom-control custom-checkbox my-4">
                                    <div class="remember">
                                        <input type="checkbox" class="custom-control-input" id="customCheck2" formControlName="isAgree" required>
                                        <label class="custom-control-label" for="customCheck2">Ja, ich stimme den <a href="https://checkntrack.net/#/datenschutzbestimmungen" target="_blank" class="register-link-cls">Datenschutzbestimmungen</a> und den <a href="https://checkntrack.net/#/nutzungsbedingungen" target="_blank" class="register-link-cls">Nutzungsbedingungen</a> zu. </label>
                                    </div>
                                </div>
								<span *ngIf="addressFormGroup.get('isAgree').invalid && (addressFormGroup.get('isAgree').dirty || addressFormGroup.get('isAgree').touched)" class="error">Sie müssen die Datenschutzbestimmungen und Nutzungsbedingungen akzeptieren.</span>
								<div class="counter-text text-right">
									<a class="btn btn-primary w-30 mt-3 login-btn mat-raised-button mat-button-base mr-3" (click)="next('home')">
										<span>Zurück</span>
									</a>
									<button class="btn btn-primary w-30 mt-3 login-btn mat-raised-button mat-button-base mr-3" (click)="register()" [disabled]='!fso.form.valid'>Registrieren</button>
									<!-- <button class="btn btn-primary w-30 mt-3 login-btn mat-raised-button mat-button-base" (click)="next('menu2')" [disabled]='!fso.form.valid'>Weiter</button> -->
								</div>
							</form>
						</div>
						<!-- <div id="menu2" class="tab-pane fade p-5 {{ clickActiveMenu2 }}" *ngIf="!success">
							<form [formGroup]="moreinfoFormGroup"  #fsr="ngForm" novalidate="" class="login-form">
								<div class="input-group mb-3">
									<input type="text" class="form-control" placeholder="Telefonnummer eingeben" aria-label="Telefonnummer eingeben" aria-describedby="basic-addon2" formControlName="phone_number">
								</div>
								<div class="mb-4">
									<span *ngIf="moreinfoFormGroup.get('phone_number').invalid && (moreinfoFormGroup.get('phone_number').dirty || moreinfoFormGroup.get('phone_number').touched)" class="error">Please enter Telefonnummer eingeben</span>
								</div>
								<div class="input-group mb-3">
									<input type="password" placeholder="Passwort" value="{{password}}" formControlName="password" [type]="hide ? 'password' : 'text'" name="password" class="form-control">
									<div class="input-group-prepend">
										<span class="input-group-text ripple">
											<img src="assets/img/RefreshKey50.png" style="height: 18px; width:18px;" (click)="autoPassword()">
										</span>
									</div>
									<div class="input-group-prepend">
										<span class="input-group-text ripple" (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
											<i class="fas fa-eye" (click)="autoPassword()"></i>
										</span>
									</div>
								</div>
								<div class="mb-4">
									<span *ngIf="moreinfoFormGroup.get('password').invalid && (moreinfoFormGroup.get('password').dirty || moreinfoFormGroup.get('password').touched)" class="error">Please enter Passwort</span>
								</div>
								<div class="counter-text text-right">
									<button class="btn btn-primary w-30 mt-3 login-btn mat-raised-button mat-button-base mr-3" (click)="register()">Registrieren</button>
									<a class="btn btn-primary w-30 mt-3 login-btn mat-raised-button mat-button-base mr-3" (click)="next('menu1')">
										<span>Zurück</span>
									</a>
									<a href="register" class="btn btn-primary w-30 mt-3 login-btn mat-raised-button mat-button-base">Reset</a>
								</div>
							</form>
						</div> -->
					</div>
				</div>
			</div>            
		</div>
		<div class="fs-logo-div-register">powered by <img class="fs-logo" src="assets/img/fioretto-systems.png"></div>
	</div>
</section>
<app-footer></app-footer>