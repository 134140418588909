<section id="function" class="section work-area ptb_100">
    <!-- Work Slider Wrapper -->
    <div class="work-wrapper d-none d-md-block">
        <div class="work-slider-wrapper" data-aos="zoom-in">
            <!-- Work Slider -->
            <ul class="work-slider owl-carousel">
                <li class="slide-item">
                    <img src="assets/img/f1.jpg" alt="" class="slider-cls">
                </li>
                <li class="slide-item">
                    <img src="assets/img/f2.jpg" alt="" class="slider-cls">
                </li>
                <li class="slide-item">
                    <img src="assets/img/f3.jpg" alt="" class="slider-cls">
                </li>
                <li class="slide-item">
                    <img src="assets/img/f4.jpg" alt="" class="slider-cls">
                </li>
            </ul>
        </div>
    </div>
    <div class="container">
        <!-- <div class="row justify-content-center d-md-none">
            <div class="col-12 col-md-10 col-lg-6">
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Few steps to Setup</h2>
                    <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                </div>
            </div>
        </div> -->
        <!-- Work Content -->
        <div class="row justify-content-end justify-content-lg-between work-content" id="work-slider-pager">
            <div class="col-12 col-sm-6">
                <a href="#" class="pager-item active">
                    <!-- Single Work -->
                    <div class="single-work d-inline-block text-center p-4">
                        <h3 class="mb-2">Herunterladen und Starten</h3>
                        <p>Laden Sie die App herunter und installieren Sie sie auf Ihrem Android- oder iOS-Telefon.Öffnen Sie die App, holen Sie sich Ihre anonyme Benutzer-ID, lesen Sie die Einführung und erteilen Sie der App die erforderlichen Genehmigungen.</p>
                    </div>
                </a>
            </div>
            <div class="col-12 col-sm-6">
                <a href="#" class="pager-item">
                    <!-- Single Work -->
                    <div class="single-work d-inline-block text-center p-4">
                        <h3 class="mb-2">Selbstgesundheitscheck</h3>
                        <p>Beantworten Sie die Frage zu Ihrer Gesundheit.Das Ergebnis wird jeweils für das Risiko und die Infektion sowie den Verlauf in 3 Kategorien hoch, mittel oder niedrig angezeigt.</p>
                    </div>
                </a>
            </div>
            <div class="col-12 col-sm-6">
                <a href="#" class="pager-item">
                    <!-- Single Work -->
                    <div class="single-work d-inline-block text-center p-4">
                        <h3 class="mb-2">ID-Verfolgung</h3>
                        <p>Unsere Bluetooth- und GPS-Algorithmen verarbeiten Ihre Daten Rufen Sie bis zu 30 Tage Ihrer letzten Bewegungen ab.</p>
                    </div>
                </a>
            </div>
            <div class="col-12 col-sm-6">
                <a href="#" class="pager-item">
                    <!-- Single Work -->
                    <div class="single-work d-inline-block text-center p-4">
                        <h3 class="mb-2">Datenschutz</h3>
                        <p>Ihre Daten werden nicht an Dritte weitergegeben und Sie können Ihre Daten jederzeit ausnahmslos löschen.</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>