<app-header-one></app-header-one>
<section class="section breadcrumb-area d-flex align-items-center">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<!-- Breamcrumb Content -->
				<div class="breadcrumb-content d-flex flex-column align-items-center text-center">
					<h3>Impressum</h3>
					<ol class="breadcrumb">
						<li class="breadcrumb-item"><a class="text-uppercase" href="index.html">Home</a></li>
						<li class="breadcrumb-item active">Impressum</li>
					</ol>
				</div>
			</div>
		</div>
	</div>
</section>
<section class="section faq-area bg-gray ptb_100">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-12 col-lg-12">
				<!-- Single Faq -->
				<div class="single-faq bg-white px-4 py-3">
					<p class="mt-3">Alle hier verwendeten Namen, Begriffe, Zeichen und Grafiken können Marken- oder
						Warenzeichen im Besitze ihrer rechtlichen Eigentümer sein. Die Rechte aller erwähnten und
						benutzten Marken- und Warenzeichen liegen ausschließlich bei deren Besitzern.</p>
						<br>
						<div class="media">
							<div class="media-body align-self-center">
								<h5>Unternehmensinformationen:</h5>
							</div>
						</div>
						<br>
						<div class="media">
							<div class="media-body align-self-center">
								<h5>Fioretto Media GmbH</h5>
							</div>
						</div>
					<p>
						Josefstrasse 22
						<br>
						36088 Hünfeld
						<br>
						Deutschland / Germany
						<br>
						Web: www.fiorettomedia.com
					</p>
						<br>
						<div class="media">
							<div class="media-body align-self-center">
								<h5>Rechtliche Angaben</h5>
							</div>
						</div>
					<p>
						Vertretungsberechtigter Geschäftsführer: Filippo Fioretto
						<br>
						Registergericht: Amtsgericht Fulda                                    
						<br>
						Registernummer: HRB 5667
						<br>                                                             
						Inhaltlich Verantwortlicher gemäß §5 Abs. 1 TMG: Filippo Fioretto
						<!-- Vertretungsberechtigter Geschäftsführer: Filippo Fioretto
						Registergericht: Amtsgericht Fulda
						Registernummer: HRB 5667
						Inhaltlich Verantwortlicher gemäß TMG
						Filippo Fioretto -->
					</p>
						<br>
						<div class="media">
							<div class="media-body align-self-center">
								<h5>Technische Angaben</h5>
							</div>
						</div>
					<p>
						Technisch Verantwortlicher:
						Filippo Fioretto
					</p>
					<br>
						<div class="media">
							<div class="media-body align-self-center">
								<h5>Markenzeichen</h5>
							</div>
						</div>
					<p>
						CHECK'N'TRACK ist als Europäische Wort-/Bildmarke angemeldet.
						<br>
						Markennummer: 018229551
					</p>
				</div>
			</div>            
		</div>
	</div>
</section>
<app-footer></app-footer>