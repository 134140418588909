<section id="benifits" class="section benifits-area ptb_100">
    <div class="container">
        <div class="row">
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="benifits-item text-center p-3">
                    <div class="feature-icon mb-4">
                        <img src="assets/img/tracking_activity_icon.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Tracking Aktivität</h3>
                        <p>Zeigen Sie Ihre Aktivitäten in den letzten 30 Tagen auf der Karte an. Wir erfassen Ihren Standort alle 15 Meter.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="benifits-item text-center p-3">
                    <div class="feature-icon mb-4">
                        <img src="assets/img/usability_icon.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Easy To Use</h3>
                        <p>Laden Sie einfach die Anwendung herunter, lassen Sie sie laufen und lassen Sie sich bei Bedarf benachrichtigen.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="benifits-item text-center p-3">
                    <div class="feature-icon mb-4">
                        <img src="assets/img/alert_icon.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Echtzeit Benarichtigungen</h3>
                        <p>Wenn sich ein Nutzer mit COVID-19 infiziert, werden Sie nach Meldung automatisch informiert.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <div class="benifits-item text-center p-3">
                    <div class="feature-icon mb-4">
                        <img src="assets/img/protection_icon.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Datenschutz</h3>
                        <p>Wir verstehen Ihre Privatsphäre und Ihre Daten sind gemäß den Gesetzen bei uns geschützt.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>