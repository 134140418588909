<app-header-one></app-header-one>
<section class="section breadcrumb-area d-flex align-items-center">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<!-- Breamcrumb Content -->
				<div class="breadcrumb-content d-flex flex-column align-items-center text-center">
					<h3>Datenschutzbestimmungen</h3>
					<ol class="breadcrumb">
						<li class="breadcrumb-item"><a class="text-uppercase" href="index.html">Home</a></li>
						<li class="breadcrumb-item active">Datenschutzbestimmungen</li>
					</ol>
				</div>
			</div>
		</div>
	</div>
</section>
<section class="section faq-area bg-gray ptb_100">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-12 col-lg-12">
				<!-- Single Faq -->
				<div class="single-faq bg-white px-4 py-3">
					<p class="mt-3">Erklärung zum Datenschutz (Privacy Policy) für Fioretto Media GmbH</p>
					<br>
					<div class="media">
						<div class="media-body align-self-center">
							<h5>Vorwort:</h5>
						</div>
					</div>
					<br>
					<p>Die Betreiber von Fioretto Media GmbH nehmen den Schutz der privaten Daten
						ernst. Die besondere Beachtung der Privatsphäre bei der Verarbeitung
						persönlicher Daten ist ein wichtiges Anliegen. Persönliche Daten werden gemäß
						den Bestimmungen der DSGVO verwendet; die Betreiber dieser Website
						verpflichten sich zur Verschwiegenheit. Diese Webseiten können Links zu
						Webseiten anderer Anbieter enthalten, auf die sich diese Datenschutzerklärung
						nicht erstreckt. Weitere wichtige Informationen finden sich auch in den
					Allgemeinen Nutzungsbedingungen.</p>
				</div>
			</div>
			<div class="col-12 col-md-12 col-lg-12">
				<!-- Single Faq -->
				<div class="single-faq bg-white px-4 py-3">
					<div class="media">
						<div class="media-body align-self-center">
							<h5>1. Personenbezogene Daten</h5>
						</div>
					</div>
					<br>
					<p class="mb-4">Personenbezogene Daten sind Informationen, die dazu genutzt werden können, die Identität
						zu erfahren. Darunter fallen Informationen wie richtiger Name, Adresse, Postanschrift,
						Telefonnummer. Informationen, die nicht direkt mit der wirklichen Identität in Verbindung
						gebracht werden (wie zum Beispiel favorisierte Webseiten oder Anzahl der Nutzer einer Site)
						fallen nicht darunter.
						<br><br>
						Man kann unser Online-Angebot grundsätzlich ohne Offenlegung der Identität nutzen. Wenn
						man sich für eine Registrierung entscheidet, sich also als Mitglied (registrierter Benutzer)
						anmeldet, kann man im individuellen Benutzerprofil persönlichen Informationen hinterlegen.
						Es unterliegt der freien Entscheidung, ob diese Daten eingegeben werden. Da versucht wird,
						für eine Nutzung des Angebots so wenig wie möglich personenbezogene Daten zu erheben,
						reicht für eine Registrierung die Angabe eines Namens – unter dem man als Mitglied geführt
						wird und der nicht mit dem realen Namen übereinstimmen muss – und die Angabe der E-Mail-
						Adresse, an die das Kennwort geschickt wird, aus. In Verbindung mit dem Zugriff auf unsere
						Seiten werden serverseitig Daten (zum Beispiel IP-Adresse, Datum, Uhrzeit und betrachtete
						Seiten) gespeichert. Es findet keine personenbezogene Verwertung statt. Die statistische
						Auswertung anonymisierter Datensätze bleibt vorbehalten.
						<br><br>
						Wir nutzen die persönlichen Daten zu Zwecken der technischen Administration der Webseiten
						und zur Kundenverwaltung nur im jeweils dafür erforderlichen Umfang. Darüber hinaus
					werden persönliche Daten nur dann gespeichert, wenn diese freiwillig angegeben werden.</p>
					<div class="media">
						<div class="media-body align-self-center">
							<h5>2. Weitergabe personenbezogener Daten</h5>
						</div>
					</div>
					<br>
					<p class="mb-4">Wir verwenden personenbezogene Informationen nur für diese Webseite. Wir geben die
						Informationen nicht ohne ausdrückliches Einverständnis an Dritte weiter. Sollten im Rahmen
						der Auftragsdatenverarbeitung Daten an Dienstleister weitergegeben werden, so sind diese an
						das Bundesdatenschutzgesetz BDSG, andere gesetzliche Vorschriften und an diese Privacy
						Policy gebunden.
						<br><br>
						Erhebungen beziehungsweise übermittlungen persönlicher Daten an staatliche Einrichtungen
					und Behörden erfolgen nur im Rahmen zwingender Rechtsvorschriften.</p>
					<div class="media">
						<div class="media-body align-self-center">
							<h5>3. Einsatz von Cookies</h5>
						</div>
					</div>
					<br>
					<p class="mb-4">Wir setzen Cookies – kleine Dateien mit Konfigurationsinformationen – ein. Sie helfen dabei,
						benutzerindividuelle Einstellungen zu ermitteln und spezielle Benutzerfunktionen zu
						realisieren. Wir erfassen keine personenbezogenen Daten über Cookies. Sämtliche Funktionen
						der Website sind auch ohne Cookies einsetzbar, einige benutzerdefinierte Eigenschaften und
					Einstellungen sind dann allerdings nicht verfügbar.</p>
					<div class="media">
						<div class="media-body align-self-center">
							<h5>4. Kinder</h5>
						</div>
					</div>
					<br>
					<p class="mb-4">Personen unter 18 Jahren sollten ohne Zustimmung der Eltern oder Erziehungsberechtigten
						keine personenbezogenen Daten an uns übermitteln. Wir fordern keine personenbezogenen
					Daten von Kindern an, sammeln diese nicht und geben sie nicht an Dritte weiter.</p>
					<div class="media">
						<div class="media-body align-self-center">
							<h5>5. Recht auf Widerruf</h5>
						</div>
					</div>
					<br>
					<p class="mb-4">Wenn Sie uns personenbezogene Daten überlassen haben, können Sie diese jederzeit im
						Benutzerprofil wieder ändern und löschen. Für eine vollständige Löschung des Accounts bitte
						an den Webmaster wenden. Bis zu diesem Zeitpunkt erfolgte Beiträge in Foren, Kommentaren,
						Terminankündigungen und Artikeln bleiben allerdings unter Umständen erhalten –
					Informationen dazu auch bei den allgemeinen Nutzungsbedingungen.</p>
					<div class="media">
						<div class="media-body align-self-center">
							<h5>6. Links zu anderen Websites</h5>
						</div>
					</div>
					<br>
					<p class="mb-4">Unser Online-Angebot enthält Links zu anderen Websites. Wir haben keinen Einfluss darauf,
					dass deren Betreiber die Datenschutzbestimmungen einhalten.</p>
					<div class="media">
						<div class="media-body align-self-center">
							<h5>7. Beiträge</h5>
						</div>
					</div>
					<br>
					<p class="mb-4">Die Beiträge auf unserer Seite sind für jeden zugänglich. Beiträge sollten vor der
						Veröffentlichung sorgfältig darauf überprüft werden, ob sie Angaben enthalten, die nicht für
						die Öffentlichkeit bestimmt sind. Die Beiträge werden möglicherweise in Suchmaschinen
					erfasst und auch ohne gezielten Aufruf dieser Website weltweit zugreifbar.</p>
					<div class="media">
						<div class="media-body align-self-center">
							<h5>8. Fragen und Kommentare</h5>
						</div>
					</div>
					<br>
					<p class="mb-4">Bei Fragen und für Anregungen und Kommentare zum Thema Datenschutz bitte per Mail an
					den Webmaster der Fioretto Media GmbH.</p>
				</div>
			</div>            
		</div>
	</div>
</section>
<app-footer></app-footer>