import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/common/global-constants';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  loginLink:any;	
  constructor() { }

  ngOnInit(): void {
  	this.loginLink = GlobalConstants.loginLink;
  }

}
