<app-header-one></app-header-one>
<section class="section breadcrumb-area d-flex align-items-center">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<!-- Breamcrumb Content -->
				<div class="breadcrumb-content d-flex flex-column align-items-center text-center">
					<h3>Nutzungsbedingungen</h3>
					<ol class="breadcrumb">
						<li class="breadcrumb-item"><a class="text-uppercase" href="index.html">Home</a></li>
						<li class="breadcrumb-item active">Nutzungsbedingungen</li>
					</ol>
				</div>
			</div>
		</div>
	</div>
</section>
<section class="section faq-area bg-gray ptb_100">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-12 col-lg-12">
				<!-- Single Faq -->
				<div class="single-faq bg-white px-4 py-3">
					<p class="mt-3">Allgemeine Nutzungsbedingungen Fioretto Media GmbH</p>
					<br>
					<div class="media">
						<div class="media-body align-self-center">
							<h5>1. Informationen zum Urheberrecht</h5>
						</div>
					</div>
					<p class="mt-4 mb-4">Alle Informationen dieser Web-Seite werden wie angegeben ohne Anspruch auf
						Richtigkeit, Vollständigkeit oder Aktualität zur Verfügung gestellt.
						Wenn nicht ausdrücklich anderweitig in dieser Publikation zu verstehen gegeben,
						und zwar in Zusammenhang mit einem bestimmten Ausschnitt, einer Datei, oder
						einem Dokument, ist jedermann dazu berechtigt, dieses Dokument anzusehen, zu
						kopieren, zu drucken und zu verteilen, unter den folgenden Bedingungen:
						Das Dokument darf nur für nichtkommerzielle Informationszwecke genutzt
						werden. Jede Kopie dieses Dokuments oder eines Teils davon muss diese
						urheberrechtliche Erklärung und das urheberrechtliche Schutzzeichen des
						Betreibers enthalten. Das Dokument, jede Kopie des Dokuments oder eines Teils
						davon dürfen nicht ohne schriftliche Zustimmung des Betreibers verändert
						werden. Der Betreiber behält sich das Recht vor, diese Genehmigung jederzeit zu
						widerrufen, und jede Nutzung muss sofort eingestellt werden, sobald eine
					schriftliche Bekanntmachung seitens des Betreibers veröffentlicht wird.</p>
					<div class="media">
						<div class="media-body align-self-center">
							<h5>2. Vertragliche Zusicherungen und Verzichterklärungen</h5>
						</div>
					</div>
					<p class="mt-4 mb-4">Die Website der Fioretto Media GmbH steht Ihnen – soweit nicht anders vereinbart – kostenlos
						zur Verfügung. Die Betreiber übernehmen keinerlei Gewähr für Richtigkeit der enthaltenen
						Informationen, Verfügbarkeit der Dienste, Verlust von auf Fioretto Media GmbH
						abgespeicherten Daten oder Nutzbarkeit für irgendeinen bestimmten Zweck.
						Die Betreiber haften auch nicht für Folgeschäden, die auf einer Nutzung des Angebotes
						beruhen.
						<br><br>
						Soweit ein Haftungsausschluss nicht in Betracht kommt, haften die Betreiber lediglich für

						grobe Fahrlässigkeit und Vorsatz. Produkt- und Firmennamen sind Marken der jeweiligen
						Eigentümer und werden auf diesen Seiten ausschließlich zu Informationszwecken eingesetzt.
						Diese Publikation könnte technische oder andere Ungenauigkeiten enthalten oder Schreib-
						oder Tippfehler. Von Zeit zu Zeit werden der vorliegenden Information Änderungen
						hinzugefügt; diese Änderungen werden in neuen Ausgaben der Publikation eingefügt. Der
						Betreiber kann jederzeit Verbesserungen und/oder Veränderungen an den Angeboten
					vornehmen, die in dieser Publikation beschrieben werden.</p>
					<div class="media">
						<div class="media-body align-self-center">
							<h5>3. Meinungsäußerungen bei Kommentaren und im Forum</h5>
						</div>
					</div>
					<p class="mt-4 mb-4">Aufgrund der sich ständig verändernden Inhalte bei Kommentaren und im Forum ist es dem
						Betreiber nicht möglich, alle Beiträge lückenlos zu sichten, inhaltlich zu prüfen und die
						unmittelbare aktive Kontrolle darüber auszuüben. Es wird keine Verantwortung für den
					Inhalt, die Korrektheit und die Form der eingestellten Beiträge übernommen.</p>
					<div class="media">
						<div class="media-body align-self-center">
							<h5>3a. Spezielle Bestimmungen für angemeldete Nutzer</h5>
						</div>
					</div>
					<p class="mt-4">Mit der Anmeldung bei Fioretto Media GmbH erklärt sich der Nutzer – nachfolgend »Mitglied«
						gegenüber dem Betreiber mit folgenden Nutzungsbedingungen einverstanden:
					Mitglieder, die sich an Diskussionsforen und Kommentaren beteiligen, verpflichten sich dazu,</p>
					<p class="mt-4">1. Sich in Ihren Beiträgen jeglicher Beleidigungen, strafbarer Inhalte, Pornographie und grober
					Ausdrucksweise zu enthalten,</p>
					<p class="mt-4">2. Die alleinige Verantwortung für die von ihnen eingestellten Inhalte zu tragen, Rechte Dritter
						(insbesondere Marken-, Urheber- und Persönlichkeitsrechte) nicht zu verletzen und die
						Betreiber von »Fioretto Media GmbH« von durch ihre Beiträge ausgelösten Ansprüchen Dritter
					vollständig freizustellen.</p>
					<p class="mt-4">3. Weder in Foren noch in Kommentaren Werbung irgendwelcher Art einzustellen oder Foren
						und Kommentare zu irgendeiner Art gewerblicher Tätigkeit zu nutzen. Insbesondere gilt das
					für die Veröffentlichung von »0900«-Rufnummern zu irgendeinem Zweck.</p>
					<p class="mt-4 mb-4">Es besteht keinerlei Anspruch auf Veröffentlichung von eingereichten Kommentaren oder
						Forenbeiträgen. Die Betreiber von »Fioretto Media GmbH« behalten sich vor, Kommentare
						und Forenbeiträge nach eigenem Ermessen zu editieren oder zu löschen. Bei Verletzungen der
						Pflichten unter 1), 2) und 3) behalten sich die Betreiber ferner vor, die Mitgliedschaft zeitlich
					begrenzt zu sperren oder dauernd zu löschen.</p>
					<div class="media">
						<div class="media-body align-self-center">
							<h5>4. Einreichen von Beiträgen und Artikeln</h5>
						</div>
					</div>
					<p class="mt-4">Soweit das Mitglied von der Möglichkeit Gebrauch macht, eigene Beiträge für redaktionellen
						Teil von »Fioretto Media GmbH« einzureichen, gilt Folgendes:
						Voraussetzung für das Posten eigener Beiträge ist, dass das Mitglied seinen vollständigen und
						korrekten Vor- und Nachnamen in sein »Fioretto Media GmbH« – Benutzerprofil eingetragen
						hat oder nach dem Einreichen des Artikels dort einträgt. Mit dem dort eingetragenen Namen
						wird der eingereichte Beitrag bei Veröffentlichung (öffentlich) gekennzeichnet.
						Das Mitglied gibt für alle Beiträge, die von ihm oder ihr zukünftig auf »Fioretto Media GmbH«
					eingereicht werden, folgende Erklärungen ab:</p>
					<p class="mt-4">1. Das Mitglied versichert, das die eingereichten Beiträge frei von Rechten Dritter,
						insbesondere Urheber-, Marken- oder Persönlichkeitsrechten sind. Dies gilt für alle
					eingereichten Beiträge und Bildwerke.</p>
					<p class="mt-4">2. Das Mitglied räumt den Betreibern von »Fioretto GmbH Media« ein uneingeschränktes
						Nutzungsrecht an den eingereichten Beiträgen ein. Dieses umfasst die Veröffentlichung im
						Internet auf »Fioretto Media GmbH« sowie auf anderen Internetservern, in Newslettern,
					Printmedien und anderen Publikationen.</p>
					<p class="mt-4">3. Eingereichte Beiträge werden auf Verlangen des Mitgliedes per Email an die Adresse des
						Webmasters wieder gelöscht bzw. anonymisiert. Die Löschung bzw. Anonymisierung erfolgt
						innerhalb von 7 Tagen nach der Mitteilung. Für Folgeschäden, die dem Mitglied aus der
						verspäteten Löschung des Beitrages entstehen haften die Betreiber nur insoweit, als sie nicht
						auf einer Pflichtverletzung des Mitgliedes (oben unter 1), 2) und 3) ) und soweit sie darüber
						hinaus auf grobem Verschulden oder Vorsatz der Betreiber von »Fioretto Media GmbH«
						beruhen. Wir weisen in diesem Zusammenhang ausdrücklich darauf hin, dass die »Fioretto
						GmbH Media« regelmäßig von Suchmaschinen indexiert wird, und dass wir keinen Einfluss
						darauf haben, ob, wo und wie lange bei uns veröffentlichte Beiträge möglicherweise auch nach

						Löschung bei der »Fioretto Media GmbH« in Datenbanken von Suchmaschinen und
					Webkatalogen gespeichert werden und abrufbar sind.</p>
					<p class="mt-4">4. Es besteht keinerlei Anspruch auf Speicherung, Veröffentlichung oder Archivierung der
						eingereichten Beiträge. Die Betreiber behalten sich vor, eingereichte Beiträge ohne Angabe
						von Gründen nicht zu veröffentlichen, vor Veröffentlichung zu editieren oder nach
					Veröffentlichung nach freiem Ermessen wieder zu löschen.</p>
					<p class="mt-4 mb-4">5. Durch die Veröffentlichung eingereichter Beiträge entstehen keinerlei Vergütungsansprüche
						(Honorare, Lizenzgebühren, Aufwendungsentschädigungen oder ähnliches) des Mitgliedes
					gegenüber der »Fioretto Media GmbH«. Die Mitarbeit ist ehrenamtlich (unentgeltlich).</p>
					<div class="media">
						<div class="media-body align-self-center">
							<h5>5. Erklärung zum Datenschutz (Privacy Policy)</h5>
						</div>
					</div>
					<p class="mt-4 mb-4">Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder
						geschäftlicher Daten genutzt wird, so erfolgt die Preisgabe dieser Daten seitens des Nutzers auf
						ausdrücklich freiwilliger Basis. Die Inanspruchnahme unseres Dienstes ist – soweit technisch
						möglich und zumutbar – auch ohne Angabe solcher Daten bzw. unter Angabe anonymisierter
						Daten oder eines Pseudonyms gestattet. Weitere wichtige Informationen zum Thema
					Datenschutz finden sich in unserer Erklärung zum Datenschutz.</p>
					<div class="media">
						<div class="media-body align-self-center">
							<h5>6. Registrierung und Passwort</h5>
						</div>
					</div>
					<p class="mt-4 mb-4">Der Benutzer ist verpflichtet, die Kombination Benutzername/Passwort vertraulich zu
						behandeln und nicht an Dritte weiterzugeben. Bei Verdacht auf Missbrauch der Zugangsdaten
					ist der Betreiber zu informieren.</p>
					<div class="media">
						<div class="media-body align-self-center">
							<h5>7. Hinweis gemäß Teledienstgesetz</h5>
						</div>
					</div>
					<p class="mt-4 mb-4">Für Internetseiten Dritter, auf die die dieses Angebot durch sog. Links verweist, tragen die
						jeweiligen Anbieter die Verantwortung. Der Betreiber ist für den Inhalt solcher Seiten Dritter
						nicht verantwortlich. Des Weiteren kann die Web-Seite ohne unser Wissen von anderen Seiten
						mittels sog. Links angelinkt werden. Der Betreiber übernimmt keine Verantwortung für
						Darstellungen, Inhalt oder irgendeine Verbindung zu dieser Web-Seite in Web-Seiten Dritter.
						Für fremde Inhalte ist der Betreiber nur dann verantwortlich, wenn von ihnen (d.h. auch von
						einem rechtswidrigen oder strafbaren Inhalt) positive Kenntnis vorliegt und es technisch
						möglich und zumutbar ist, deren Nutzung zu verhindern. Der Betreiber ist nach dem
					Teledienstgesetz jedoch nicht verpflichtet, die fremden Inhalte ständig zu überprüfen.</p>
					<div class="media">
						<div class="media-body align-self-center">
							<h5>Kontakt</h5>
						</div>
					</div>
					<p class="mt-4 mb-4">Bei Fragen rund um die Fioretto Media GmbH, bitte an den Webmaster richten.</p>
					<div class="media">
						<div class="media-body align-self-center">
							<h5>Rechtswirksamkeit</h5>
						</div>
					</div>
					<p class="mt-4">Diese Allgemeinen Nutzungsbedingungen beziehen sich auf die Fioretto Media GmbH Website.
						Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht
						mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in
					ihrem Inhalt und ihrer Gültigkeit davon unberührt.</p>
				</div>
			</div>         
		</div>
	</div>
</section>
<app-footer></app-footer>