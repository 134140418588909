<section id="home" class="section welcome-area d-flex align-items-center">
    <div class="container">
        <div class="row align-items-center">
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-7 col-lg-6">
                <div class="welcome-intro">
                    <h1>CHECK'N'TRACK</h1>
                    <h3 class="fw-3 mt-2 mt-sm-3">Ihr Begleiter in der Krise!</h3>
                    <p class="my-3">APP installieren, in der Nähe behalten und Anonym Leben retten.</p>
                    <div class="button-group store-buttons mb-4">
                        <a href="#" class="btn btn-bordered">
                            <i class="icofont icofont-brand-android-robot dsp-tc"></i>
                            <p class="dsp-tc">Download im
                                <br> <span>Google Play</span></p>
                        </a>
                        <a href="#" class="btn btn-bordered">
                            <i class="icofont icofont-brand-apple dsp-tc"></i>
                            <p class="dsp-tc">Download im
                                <br> <span>Apple Store</span></p>
                        </a>
                    </div>
                    <div class="comming-soon-cls">* Nicht verfügbar</div>
                    <!-- <div class="button-group">
                        <a href="#" class="btn btn-bordered"><span>iOS App</span></a>
                        <a href="#" class="btn btn-bordered d-none d-sm-inline-block">Android App</a>
                    </div> -->
                </div>
            </div>
            <div class="col-12 col-md-5 col-lg-6">
                <!-- Welcome Thumb -->
                <div class="welcome-thumb text-center" data-aos="fade-left" data-aos-delay="500" data-aos-duration="1000">
                    <img src="assets/img/banner_bg_mobile.png" alt="" class="banner-bg-mobile">
                </div>
                <!-- Video Icon -->
                <!-- <div class="video-icon d-none d-lg-block">
                    <a class="play-btn" data-fancybox href="https://www.youtube.com/watch?v=hs1HoLs4SD0">
                        <i class="icofont-ui-play"></i>
                    </a>
                </div> -->
            </div>
        </div>
    </div>
</section>