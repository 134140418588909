<section id="presse" class="start-free-area">
    <div class="container">
        <div class="col-12">
            <!-- Start Free Content -->
            <div class="start-free-content d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between shadow-lg" data-aos="fade-up">
                <!-- Start Free Content -->
                <div class="start-free-text">
                    <h2 class="mb-2">Pressemitteilung & Kontakt</h2>
                    <span>Fioretto Media GmbH</span>
                    <br><br>
                    <span>Josefstrasse 22, 36088 Hünfeld</span>
                    <br>
                    <span>Pressesprecher: Alexander Knapp</span>
                    <!-- <span>Pressesprecher: Alexander Knapp, Josefstrasse 22, 36088 Hünfeld</span> -->
                </div>
                <!-- Start Free Button -->
                <div class="start-free-btn mt-4 mt-lg-0">
                    <a href="/assets/img/New-Press-Release.pdf" class="btn btn-bordered" target="_blank"><span>Pressemitteilung PDF</span></a>
                    <!-- <a href="/assets/img/Press-Release.pdf" class="btn btn-bordered" target="_blank" download="Press-Release.pdf"><span>Pressemitteilung PDF</span></a> -->
                </div>
            </div>
        </div>
    </div>
</section>